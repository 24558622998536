.ant-radio-group {
    .ant-radio-button-wrapper.radio-button-success {
        color: $success-default;
    }
    .ant-radio-button-wrapper.radio-button-primary {
        color: $primary-default;
    }
    .ant-radio-button-wrapper.radio-button-warning {
        color: #eecb30;
    }
    .ant-radio-button-wrapper.radio-button-danger {
        color: $danger-default; 
    }
    .ant-radio-button-wrapper.radio-button-orange {
        color: orange; 
    }
    .ant-radio-button-wrapper.radio-button-secondary {
        color: #F08340; 
    }
}