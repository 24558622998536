.ant-btn {
    font-size: $font-size-base !important;
    border-radius: $border-radius-base;
}
.ant-btn-primary {
    background-color: $primary-default;
    border-color: $primary-default;
    color: $white;

    &:hover,
    &:focus {
        color: $white;
        background-color: lighten($primary-default, 5%);
        border-color: lighten($primary-default, 5%);
    }
    &:active {
        color: $white;
        background-color: darken($primary-default, 5%);
        border-color: darken($primary-default, 5%);
    }

    &.active {
        color: $white;
        background-color: darken($primary-default, 5%);
        border-color: darken($primary-default, 5%);

        &:hover,
        &:focus {
            background-color: darken($primary-default, 5%);
            border-color: darken($primary-default, 5%);
        }
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
        &:focus {
            box-shadow: none;
        }
    }
    &.disabled,
    &[disabled] {
        color: $white;
        background-color: $gray-light-25;
        border-color: lighten($primary-light,25%) ;

        &.active,
        &:active,
        &:hover,
        &:focus {
            color: $white;
            background-color: $gray-light-25;
            border-color: lighten($primary-light,25%) ;
        }
    }

    &.ant-btn-clicked {
        &:after {
            border-color: $primary-default;
        }
    }
}

.ant-btn-circle,
.ant-btn-circle-outline {
    border-radius: $border-radius-circle;
}

