// ! ASK FOR PERMISSION BEFORE CHANGING THIS FILE ! BY VISHAL
.ant-input{
    height: auto;
    border-radius: $inp-border-radius;
    &.ant-input:hover {
        border-color: $inp-border-color;
    }
    &:focus,
    .ant-input-focused {
        border-color: $inp-border-color;
        box-shadow: 0 0 0 2px rgba($inp-border-color, .2)
    }
}

.ant-input-affix-wrapper {
    border-radius: $inp-border-radius;
}

// date picker styles
.ant-picker-range,
.ant-picker {
    border-radius: $inp-border-radius;
}

.ant-input-group-compact {
    .ant-input-number {
        border-radius: $inp-border-radius 0 0 $inp-border-radius !important;
    }
    .ant-select {
        border: none;
        .ant-select-selector {
            border-radius: 0 $inp-border-radius $inp-border-radius 0 !important;
        }
    }
}