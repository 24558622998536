.ant-select {
    .ant-select-selector {
     border-radius: $inp-border-radius !important;
    }
    &:not(.ant-select-disabled) {
        &:hover {
            .ant-select-selector {
                border-radius: $inp-border-radius;
            }
        }
    }
}