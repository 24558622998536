// ! BRAND COLORS DO NOT CHANGE WITHOUT CONSULTING DESIGN TEAM AND FE MANAGER
$primary-default-hover : #118adb;
$primary-light:         #4bb1f4;
$primary-default:       #0b71b4;
$primary-dark:          #04263c;

$secondary-light:       #fbbf24;
$secondary-default:     #f59e0B;
$secondary-dark:        #D97706;

$danger-light:          #fb7272; 
$danger-default:        #E60707;
$danger-dark:           #6a0303;

$success-light:         #1ffc62;
$success-default:       #029a30;
$success-dark:          #001c09;

$warning-light:         #fad577;
$warning-default:       #eaaa08;
$warning-dark:          #6f5004;

// * BASE THEME
$white:         #ffffff;
$black:         #000000;
$content-bg:    #fcfcfd;
$header-border-color: #e8e8e8;
$border-radius-base: .50rem;
$border-radius-circle: 50%;

// * gray colors
$gray:               #808080;
$gray-light-25:      lighten($gray,25%);
$gray-light-50:      lighten($gray,50%);
$gray-light-100:     lighten($gray,100%);
$gray-dark-25:       darken($gray,25%);
$gray-dark-50:       darken($gray,50%);
$gray-dark-100:      darken($gray,100%);

$inp-border-color:          $primary-light !default;
$inp-border-radius:         .25rem !default;
$inp-border-radius-lg:      .3rem !default;
$inp-border-radius-sm:      .2rem !default;
$inp-border-radius-xs:      .125rem !default;
$inp-border-radius-circle:  50% !default;
$inp-border-radius-round:   50px !default;
$inp-border-width:          1px !default;

// * TABLE COLORS
$table-bg:          $white;
$table-th-bg:       #f2f4f7;
$table-td-padding:  10px;
$table-th-padding:  8px;

// * TEXT THEORY
$font-size-base:     0.75rem !default;

// * TOOLTIP COLORS
$tooltip-bg:         $white;
$tooltip-color:      $black;