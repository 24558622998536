// ! ASK FOR PERMISSION BEFORE CHANGING THIS FILE ! BY VISHAL
.ant-table {
    thead {
        > tr {
            >th {
                overflow-y: hidden;
                padding: $table-th-padding;
                background: $table-th-bg;
                &::before{
                    display: none;
                }
                .ant-table-selection .ant-checkbox-inner {
                    border: 1px solid red;
                }
            }
        }
    }
}

.std-listing {
    .ant-table {
        // max-height: 57dvh !important;
    }
}
.ant-table-tbody {
    >tr {
        >td {
            padding: $table-td-padding;
        }
    }
}