.hezky-bg-blue {
  background-color: $primary-default;
}
.hezky-bg-blue:hover {
  background-color: $primary-default-hover;
}
.hezky-blue-font {
  color: $primary-default;
}
.text-xsm {
  font-size: 0.675 rem;
}

.custom-link :active {
  text-decoration: none;
  color: #fff;
}

.custom-link:hover {
  color: #fff;
  text-decoration: none;
}

.required:after {
  content: "*";
  color: red;
}
// Uploader CSS
.upload-container {
  border: 2px dashed #d9d9d9;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin: 10px 0;
}

.uploaded-file {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.uploaded-file span {
  margin-right: 10px;
}

.custom-file-upload {
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: 5px;
  background-color: #fff;
  border:none !important;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
}

.custom-file-upload input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-file-upload span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.custom-file-upload:hover {
  background-color: #fff;
  border:none !important;
}

.tooltipStyles {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  color: #0B71B4;
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  background-color: white;
}
.ant-empty.empty-incident {
  .ant-empty-image {
    height: 60px !important;
  }
}
