/* You can add global styles to this file, and also import other style files */
// * Tailwind CSS Imports
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// * App Styles
@import "./styles/app.scss";

// * Custom Styles
html,
body {
    height: 100% !important;
    width: 100% !important;
    font-optical-sizing: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* For Chrome, Safari, and Opera */
::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    border-radius: $border-radius-base !important;
}
::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: $border-radius-base;
}
/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: $border-radius-base !important;
    background: rgba($primary-default, 0.8);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $primary-default
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}