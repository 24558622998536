// ! ASK FOR PERMISSION BEFORE CHANGING THIS FILE ! BY VISHAL
.ant-layout-header .ant-menu {
    line-height: 3.5;
    height: 100%;
}
.ant-menu {
    .ant-menu-item {
        margin: 0 !important;
        font-weight: 400 !important;
        cursor: pointer !important;
        padding: 0 22px !important;
        &:hover {
            a { 
                color: $primary-default !important;
            }
            &::after {
                border-bottom: 3px solid $primary-default !important;
            }
        }
    }
    .ant-menu-item-selected {
        align-items: baseline !important;
        &::after {
            border-bottom: 3px solid $primary-default !important;
        }
        span {
            a {
                font-weight: 600 !important;
                color: $primary-default!important;
            }
        }
    }
}